import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import NumberField from '../NumberField';
import { LineNumber, RemoveButton } from './QuotationVolumesItemsStyled';

const QuotationVolumesItems = ({
  index,
  hasRemove,
  onRemove,
  handleBlurWeight,
  handleBlurMeasure,
}) => (
  <Row className="form-row align-items-start">
    <Col md="1">
      <LineNumber>{`#${index + 1}`}</LineNumber>
    </Col>
    <Col xs="6" md="2">
      <NumberField
        label="Nº de Volumes"
        placeholder="Qtd"
        name={`volumes.${index}.quantity`}
      />
    </Col>
    <Col xs="6" md="2">
      <NumberField
        name={`volumes.${index}.weight`}
        label="Peso por vol."
        placeholder="em kg"
        maskOptions={{
          suffix: ' kg',
          prefix: '',
          thousandsSeparatorSymbol: '.',
          allowDecimal: true,
          decimalSymbol: ',',
          decimalLimit: 3,
        }}
        onBlur={handleBlurWeight}
      />
    </Col>
    <Col>
      <NumberField
        name={`volumes.${index}.height`}
        label="Altura"
        placeholder="em cm"
        maskOptions={{
          suffix: ' cm',
          prefix: '',
          thousandsSeparatorSymbol: '.',
          allowDecimal: true,
          decimalSymbol: ',',
        }}
        onBlur={handleBlurMeasure}
      />
    </Col>
    <Col>
      <NumberField
        name={`volumes.${index}.width`}
        label="Largura"
        placeholder="em cm"
        maskOptions={{
          suffix: ' cm',
          prefix: '',
          thousandsSeparatorSymbol: '.',
          allowDecimal: true,
          decimalSymbol: ',',
        }}
        onBlur={handleBlurMeasure}
      />
    </Col>
    <Col>
      <NumberField
        name={`volumes.${index}.length`}
        label="Comprimento"
        placeholder="em cm"
        maskOptions={{
          suffix: ' cm',
          prefix: '',
          thousandsSeparatorSymbol: '.',
          allowDecimal: true,
          decimalSymbol: ',',
        }}
        onBlur={handleBlurMeasure}
      />
    </Col>
    {hasRemove && (
      <Col className="col col-1 text-center align-self-center">
        <RemoveButton
          color="danger"
          onClick={onRemove}
          className="rounded-circle"
        >
          -
        </RemoveButton>
      </Col>
    )}
  </Row>
);

QuotationVolumesItems.propTypes = {
  index: PropTypes.number.isRequired,
  hasRemove: PropTypes.bool,
  onRemove: PropTypes.func,
  handleBlurWeight: PropTypes.func,
  handleBlurMeasure: PropTypes.func,
};

QuotationVolumesItems.defaultProps = {
  hasRemove: false,
  onRemove: () => {},
  handleBlurWeight: () => {},
  handleBlurMeasure: () => {},
};

export default QuotationVolumesItems;
