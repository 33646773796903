import styled from 'styled-components';
import { Button } from 'reactstrap';

export const RemoveButton = styled(Button)`
  width: 30px;
  margin-top: 20px;
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
`;

export const LineNumber = styled.h2`
  color: #ced4da;
  font-size: 2rem
  font-family: 'Roboto', sans-serif;
  padding-top: 2.5rem;
`;
