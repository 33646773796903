import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { getIn, Field as FormikField } from 'formik';
import { FormFeedback, Input, InputGroup } from 'reactstrap';
import classnames from 'classnames';
import { Visibility, VisibilityOff } from 'styled-icons/material';
import * as S from './styled';
import Field from '../Field';

const iconStyle = {
  width: '24px',
  paddingLeft: 4,
  transform: 'translateY(-2px)',
  color: '#000',
};

const PasswordField = ({ name, label, isInvalid, isValid, ...props }) => {
  const [type, setType] = useState('password');

  const handleClickShowPassword = () => {
    setType(type === 'password' ? 'text' : 'password');
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  return (
    <Field label={label}>
      <FormikField
        name={name}
        render={({ field, form }) => {
          const isFormSubmitted = form.submitCound > 0;
          const isTouched = isFormSubmitted || getIn(form.touched, field.name);
          const errors = getIn(form.errors, field.name);
          const isFieldInvalid = isInvalid || (isTouched && errors);
          const isFieldValid = isValid || (isTouched && !isFieldInvalid);

          return (
            <>
              <InputGroup
                className={classnames('', {
                  'is-invalid': isFieldInvalid,
                  'is-valid': isFieldValid,
                })}
              >
                <Input
                  type={type}
                  className={classnames('form-control form-control-lg', {
                    'is-invalid': isFieldInvalid,
                    'is-valid': isFieldValid,
                  })}
                  style={{ borderRight: 0 }}
                  {...field}
                  {...props}
                />
                <S.InputGroupAddon addonType="append">
                  <S.Button
                    valid={isFieldValid ? 1 : 0}
                    invalid={isFieldInvalid ? 1 : 0}
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {type === 'password' ? (
                      <VisibilityOff style={iconStyle} />
                    ) : (
                      <Visibility style={iconStyle} />
                    )}
                  </S.Button>
                </S.InputGroupAddon>
              </InputGroup>
              {isFieldInvalid && <FormFeedback>{errors}</FormFeedback>}
            </>
          );
        }}
      />
    </Field>
  );
};

PasswordField.propTypes = {
  isValid: PropTypes.bool,
  isInvalid: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
};

PasswordField.defaultProps = {
  isValid: false,
  isInvalid: false,
  label: 'Senha',
  name: 'password',
};

export default memo(PasswordField);
