import client from '../apollo/client';
import { createQuotation as createQuotationQuery } from '../queries/quotation';

export const createQuotation = (variables, utm) => {
  return client
    .mutate({
      variables: { ...variables, utm },
      mutation: createQuotationQuery,
    })
    .then(({ data }) => data.createQuotation);
};

export default {
  createQuotation,
};
