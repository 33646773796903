import styled from 'styled-components';
import {
  Button as RButton,
  InputGroupAddon as RInputGroupAddon,
} from 'reactstrap';

const getButtonColor = ({ valid, invalid }) => {
  if (valid) {
    return `border-color: #28a745 !important;`;
  }
  if (invalid) {
    return `border-color: #dc3545 !important;`;
  }
  return 'border-color: #ced4da !important;';
};

export const Button = styled(RButton)`
  background: transparent !important;
  border-left: 0;
  ${getButtonColor}
  &:hover,
  &:active,
  &:focus {
    background: transparent !important;
    color: inherit !important;
    ${getButtonColor}
  }
`;

export const InputGroupAddon = styled(RInputGroupAddon)`
  background-color: white;
  border-right: 0;
`;
