export const currencyParser = (value = '') => {
  const parsedValue = value
    .replace('R$ ', '')
    .replace(/\./g, '')
    .replace(',', '.');

  return parseFloat(parsedValue);
};

export const suffixPrefixParser = (value = '') => {
  const parsedValue = value
    .replace('R$ ', '')
    .replace(/\./g, '')
    .replace(',', '.')
    .replace('kg ', '')
    .replace('cm', '')
    .replace('m', '');

  return parseFloat(parsedValue);
};

export default currencyParser;
