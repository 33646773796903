import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { get, reduce } from 'lodash';
import { Link } from 'gatsby';
import { Alert, Button, Col, Row, Spinner } from 'reactstrap';
import { Formik, Form, FieldArray } from 'formik';
import Yup from '../../utils/yup';
import ZipcodeField from '../ZipcodeField';
import NumberField from '../NumberField';
import QuotationVolumesItems from './QuotationVolumesItems';
import { currencyParser, suffixPrefixParser } from '../../utils/currencyParser';
import { createQuotation } from '../../api/quotations';
import { registerClick, PRE_QUOTATION_VOLUMES } from '../../api/gtm';
import useUtm from '../../hooks/useUtm';
import useAlert, { ALERT_TYPES } from '../../hooks/useAlert';

const MIN_MEASURE_LIMIT = 3;
const MEASURE_LIMIT = 300; // 300cm || 3m
const WEIGHT_LIMIT = 60; // 60kg

const ERRORS = {
  measureMax: (
    <p style={{ fontSize: '.8em', marginBottom: '0' }}>
      Infelizmente as transportadoras só levam cargas com tamanho de até 3
      metros.
    </p>
  ),
};

const WARNINGS = {
  weight: (
    <>
      <p style={{ fontSize: '.8em' }}>
        Volumes com peso superior a 60kg são livres de carga (carregamento) e
        descarga (descarregamento) por parte da transportadora.
      </p>
      <p style={{ fontSize: '.8em', marginBottom: '0' }}>
        O cliente entende e concorda que deverá ter pessoas ou máquinas no local
        de coleta e entrega que auxilie no processo.
      </p>
    </>
  ),
  measureMin: (
    <p style={{ fontSize: '.8em', marginBottom: '0' }}>
      Opa, você tem certeza que a dimensão está correta? Lembrando, 1 metro =
      100cm
    </p>
  ),
};

const getParsedVolume = ({ quantity, ...rest }) => {
  const parsedValues = Object.keys(rest).reduce(
    (acc, key) => ({
      ...acc,
      [key]: suffixPrefixParser(rest[key]),
    }),
    {}
  );

  return {
    ...parsedValues,
    quantity: parseInt(quantity, 10),
  };
};

const getParsedValues = ({
  invoice_total: invoiceTotal,
  volumes,
  from,
  to,
}) => ({
  from,
  invoice_total: currencyParser(invoiceTotal).toFixed(2),
  to,
  volumes: volumes.map(getParsedVolume),
});

const cepRegex = new RegExp(/\d{5}-\d{3}/);

const validationSchema = Yup.object().shape({
  from: Yup.string()
    .required('É obrigatório')
    .matches(cepRegex, 'Informe um CEP válido'),
  to: Yup.string()
    .required('É obrigatório')
    .matches(cepRegex, 'Informe um CEP válido'),
  invoice_total: Yup.number()
    .delocalize()
    .positive('Informe um valor minímo válido')
    .required('É obrigatório'),
  volumes: Yup.array()
    .of(
      Yup.object().shape({
        quantity: Yup.number()
          .delocalize()
          .positive('Informe um valor minímo válido')
          .typeError('Informe um valor válido')
          .required('É obrigatório'),
        width: Yup.number()
          .delocalize()
          .positive('Informe um valor minímo válido')
          .typeError('Informe um valor válido')
          .required('É obrigatório'),
        height: Yup.number()
          .delocalize()
          .positive('Informe um valor minímo válido')
          .typeError('Informe um valor válido')
          .required('É obrigatório'),
        length: Yup.number()
          .delocalize()
          .positive('Informe um valor minímo válido')
          .typeError('Informe um valor válido')
          .required('É obrigatório'),
        weight: Yup.number()
          .delocalize()
          .positive('Informe um valor minímo válido')
          .typeError('Informe um valor válido')
          .required('É obrigatório'),
      })
    )
    .required()
    .min(1),
});

const isInLimit = ({ volumes = [] }) => {
  const [maxLimit, minLimit] = [MEASURE_LIMIT, MIN_MEASURE_LIMIT];

  return reduce(
    volumes,
    (verifiedLimits, { length, width, height, weight }) => ({
      isAboveMeasureLimit:
        verifiedLimits.isAboveMeasureLimit ||
        length > maxLimit ||
        width > maxLimit ||
        height > maxLimit,
      isBelowMeasureLimit:
        verifiedLimits.isBelowMeasureLimit ||
        length < minLimit ||
        width < minLimit ||
        height < minLimit,
      isAboveWeightLimit:
        verifiedLimits.isAboveWeightLimit || weight > WEIGHT_LIMIT,
    }),
    {
      isAboveMeasureLimit: false,
      isBelowMeasureLimit: false,
      isAboveWeightLimit: false,
    }
  );
};

const QuotationVolumes = ({
  stepInitialValues,
  goToStep,
  updateStepValues,
}) => {
  const utm = useUtm();
  const { showAlert, Alert: AlertError } = useAlert();
  const [limitsExceeded, setLimitsExceeded] = useState({});
  const [addressTo, setAddressTo] = useState('');
  const [addressFrom, setAddressFrom] = useState('');

  const onSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    const variables = getParsedValues(values);
    const limits = isInLimit(variables);
    setLimitsExceeded(limits);
    if (!limits.isAboveMeasureLimit) {
      try {
        const { best_price: bestPrice, code } = await createQuotation(
          variables,
          utm
        );
        updateStepValues({
          result: {
            ...variables,
            bestPrice,
            code,
            addresses: { from: addressFrom, to: addressTo },
          },
        });
        registerClick({ event: PRE_QUOTATION_VOLUMES });
        goToStep(3);
      } catch (error) {
        const code = get(error, 'graphQLErrors[0].code', null);
        const message = get(error, 'graphQLErrors[0].message', null);
        switch (code) {
          case 15:
            showAlert({
              color: ALERT_TYPES.error,
              message:
                'Nenhuma transportadora encontrada que atenda a origem e destino com as dimensões informada.',
            });
            break;
          default:
            showAlert({ color: ALERT_TYPES.error, message });
        }
      }
    }
    setSubmitting(false);
  };

  const handleBlurWeight = values => event => {
    const value = suffixPrefixParser(event.target.value);
    const { isAboveWeightLimit } = isInLimit(getParsedValues(values));
    setLimitsExceeded(prevState => ({
      ...prevState,
      isAboveWeightLimit: isAboveWeightLimit || value > WEIGHT_LIMIT,
    }));
  };

  const handleBlurMeasure = values => event => {
    const value = suffixPrefixParser(event.target.value);
    const { isBelowMeasureLimit } = isInLimit(getParsedValues(values));
    setLimitsExceeded(prevState => ({
      ...prevState,
      isBelowMeasureLimit: isBelowMeasureLimit || value < MIN_MEASURE_LIMIT,
    }));
  };

  return (
    <Formik
      initialValues={{
        to: '',
        from: '',
        volumes: [{}],
        ...stepInitialValues,
      }}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      render={({ isSubmitting, values, errors }) => (
        <Form>
          <Row className="form-row">
            <Col>
              <ZipcodeField
                label="CEP Remetente"
                placeholder="CEP de Origem"
                name="from"
                initialValue={values.from}
                setAddress={setAddressFrom}
              />
            </Col>
            <Col>
              <ZipcodeField
                label="CEP Destinatário"
                placeholder="CEP de Destino"
                name="to"
                initialValue={values.to}
                setAddress={setAddressTo}
              />
            </Col>

            <Col sm="3">
              <NumberField
                label="Valor da nota fiscal"
                name="invoice_total"
                placeholder="R$"
                maskOptions={{
                  prefix: 'R$ ',
                  thousandsSeparatorSymbol: '.',
                  allowDecimal: true,
                  decimalSymbol: ',',
                  requireDecimal: false,
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              {limitsExceeded.isBelowMeasureLimit && (
                <Alert color="warning">{WARNINGS.measureMin}</Alert>
              )}
              {limitsExceeded.isAboveMeasureLimit && (
                <Alert color="danger">{ERRORS.measureMax}</Alert>
              )}
              {limitsExceeded.isAboveWeightLimit && (
                <Alert color="warning">{WARNINGS.weight}</Alert>
              )}
            </Col>
          </Row>
          {values.volumes && (
            <FieldArray
              name="volumes"
              render={arrayHelpers => (
                <>
                  {values.volumes.map((volume, index) => (
                    <QuotationVolumesItems
                      index={index}
                      hasRemove={index > 0}
                      onRemove={() => arrayHelpers.remove(index)}
                      key={index.toString()}
                      handleBlurWeight={handleBlurWeight(values)}
                      handleBlurMeasure={handleBlurMeasure(values)}
                    />
                  ))}
                  <Row>
                    <AlertError />
                  </Row>
                  <Row>
                    <Col md="4">
                      <Button
                        color="light"
                        size="sm"
                        onClick={() => arrayHelpers.push({})}
                      >
                        + Adicionar volume
                      </Button>
                    </Col>
                    <Col
                      md="4"
                      className="ml-auto d-flex justify-content-between align-items-center"
                    >
                      <Link className="text-secondary" to="/">
                        Voltar
                      </Link>
                      <Button
                        disabled={
                          isSubmitting || Object.keys(errors).length > 0
                        }
                        type="submit"
                        size="lg"
                        className="d-flex justify-content-center align-items-center"
                      >
                        {isSubmitting ? (
                          <>
                            <Spinner size="sm" color="light" />
                            <small> Carregando</small>
                          </>
                        ) : (
                          'Cotar agora'
                        )}
                      </Button>
                    </Col>
                  </Row>
                </>
              )}
            />
          )}
        </Form>
      )}
    />
  );
};

QuotationVolumes.propTypes = {
  goToStep: PropTypes.func.isRequired,
  stepInitialValues: PropTypes.shape({
    from: PropTypes.string,
    to: PropTypes.string,
    invoice_total: PropTypes.number,
    volumes: PropTypes.arrayOf(
      PropTypes.shape({
        quantity: PropTypes.number,
        weight: PropTypes.number,
        height: PropTypes.number,
        width: PropTypes.number,
        length: PropTypes.number,
      })
    ),
  }),
  updateStepValues: PropTypes.func.isRequired,
};

QuotationVolumes.defaultProps = {
  stepInitialValues: {},
};

export default memo(QuotationVolumes);
