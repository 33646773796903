import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Row, Label } from 'reactstrap';
import { VolumeRow } from './QuotationResultStyled';

const getCityAndState = value => {
  const splitedValue = value.split('-');
  if (splitedValue.length > 1) {
    return splitedValue[1].trim();
  }
  return value.trim();
};

const QuotationResult = ({
  invoice_total: invoiceTotal,
  volumes,
  previousStep,
  addresses,
}) => {
  const totalWeight = volumes.reduce(
    (weight, volume) => weight + volume.weight,
    0
  );

  return (
    <>
      <Row form className="d-flex align-items-end">
        <Col className="form-group col-6 col-md-4">
          <strong>Cidade do Remetente</strong>
          <Label>{getCityAndState(addresses.from)}</Label>
        </Col>
        <Col className="form-group col-6 col-md-4">
          <strong>Cidade do Destinatário</strong>
          <Label>{getCityAndState(addresses.to)}</Label>
        </Col>
        <Col className="form-group d-flex flex-column col-6 col-md-2">
          <strong>Peso total</strong>
          <Label>{`${totalWeight} kg`}</Label>
        </Col>
        <Col className="form-group d-flex flex-column col-6 col-md-2">
          <strong>Valor da NFe</strong>
          <Label>{`R$ ${invoiceTotal.toString().replace('.', ',')}`}</Label>
        </Col>
      </Row>

      {volumes.map((volume, index) => (
        <VolumeRow form key={index.toString()}>
          <Col className="form-group col-6 col-md">
            <strong>{`#${index + 1}`}</strong>
            {` ${volume.quantity} volumes`}
          </Col>
          <Col className="form-group col-6 col-md">
            <strong>Peso por vol.</strong>
            {` ${volume.weight} kg`}
          </Col>
          <Col className="form-group col col-md-2">
            <strong>Alt.</strong>
            {` ${volume.height} cm`}
          </Col>
          <Col className="form-group col col-md-2">
            <strong>Larg.</strong>
            {` ${volume.width} cm`}
          </Col>
          <Col className="form-group col col-md-2">
            <strong>Compr.</strong>
            {` ${volume.length} cm`}
          </Col>
        </VolumeRow>
      ))}
      <Row className="text-right">
        <Col>
          <Button size="sm" color="link" onClick={() => previousStep()}>
            Editar dados
          </Button>
        </Col>
      </Row>
    </>
  );
};

QuotationResult.propTypes = {
  addresses: PropTypes.shape({
    from: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
  }).isRequired,
  invoice_total: PropTypes.number.isRequired,
  previousStep: PropTypes.func.isRequired,
  volumes: PropTypes.arrayOf(
    PropTypes.shape({
      quantity: PropTypes.number,
      weight: PropTypes.number,
      height: PropTypes.number,
      width: PropTypes.number,
      length: PropTypes.number,
    })
  ).isRequired,
};

export default memo(QuotationResult);
