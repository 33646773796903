import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import querystring from 'query-string';
import QuotationResult from './QuotationResult';
import SignupForm from '../SignupForm';
import SectionTitle from '../SectionTitle';
import { signup } from '../../api/signup';
import { registerClick, PRE_QUOTATION_SIGNUP } from '../../api/gtm';
import useUtm from '../../hooks/useUtm';
import useAlert from '../../hooks/useAlert';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Section = styled.div`
  @media (max-width: 768px) {
    ${({ mobileOrder }) => (mobileOrder >= 0 ? `order: ${mobileOrder}` : ``)}
  }
`;

const Title = styled.h4`
  margin: 2rem 0;
  text-align: center;
  @media (max-width: 768px) {
    margin: 1.5rem 0;
  }
`;

const PriceButton = styled.span`
  color: #8bc34a;
  font-weight: bold;
  padding: 12px;
  display: inline-block;
  border-radius: 5px;
  font-size: 3rem;
  margin-top: 1rem;
  @media (max-width: 768px) {
    margin-top: 0.5rem;
  }
`;

const PricesImage = styled.div`
  height: 580px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(${({ image }) => image});
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3rem;
  text-align: center;
  cursor: pointer;
  @media (max-width: 768px) {
    height: 300px;
    padding: 2rem 1rem;
  }
`;

const appUrl = process.env.APP_URL;

const QuotationSignup = ({ stepInitialValues, previousStep }) => {
  const signupRef = useRef(null);
  const utm = useUtm();
  const canRenderResult = stepInitialValues && stepInitialValues.volumes;

  const { Alert } = useAlert(
    {
      isOpen: true,
      message: `Para validar sua cotação e visualizar todas as transportadoras realize seu cadastro e informe o tipo da carga.`,
    },
    false
  );

  const verbosePrice =
    stepInitialValues &&
    stepInitialValues.bestPrice &&
    stepInitialValues.bestPrice.toFixed(2).replace('.', ',');

  const { file = {} } = useStaticQuery(graphql`
    {
      file(name: { in: "prices" }) {
        publicURL
      }
    }
  `);

  const redirect = ({ token }) => {
    const search =
      Object.keys(utm).length > 0 ? querystring.stringify(utm) : '';
    window.location.href = `${appUrl}confirmar-email?q=${stepInitialValues.code}&token=${token}&${search}`;
  };

  const onSubmit = async (
    { company_name: name, ...values },
    { setSubmitting, setErrors }
  ) => {
    setSubmitting(true);
    try {
      const result = await signup(
        {
          ...values,
          name,
        },
        utm
      );
      const { data } = result;
      registerClick({ event: PRE_QUOTATION_SIGNUP });
      redirect(data.signup);
    } catch (e) {
      const errors = [
        { key: 'document', word: 'cnpj' },
        { key: 'email', word: 'email' },
      ];

      const fieldError = errors.find(({ word }) =>
        e.message.toLowerCase().includes(word)
      );

      if (fieldError) {
        setErrors({
          [fieldError.key]: e.message.replace('GraphQL error: ', ''),
        });
      }
      throw e;
    } finally {
      setSubmitting(false);
    }
  };

  const goToSignup = () => {
    window.scrollTo({
      behavior: 'smooth',
      top: signupRef.current.offsetTop + 200 || 0,
    });
  };

  return (
    <Wrapper>
      <Section mobileOrder={1}>
        {canRenderResult && (
          <QuotationResult {...stepInitialValues} previousStep={previousStep} />
        )}
      </Section>
      <Section mobileOrder={0} className="pt-2 pb-3">
        <PricesImage onClick={goToSignup} image={file.publicURL}>
          <Title>
            Encontramos fretes a partir de
            <br />
            <PriceButton>{`R$ ${verbosePrice}`}</PriceButton>
          </Title>
          <Alert />
        </PricesImage>
      </Section>
      <Section mobileOrder={2} ref={signupRef}>
        <SectionTitle>Cadastre-se agora</SectionTitle>
        <SignupForm onSubmit={onSubmit} />
      </Section>
    </Wrapper>
  );
};

QuotationSignup.propTypes = {
  previousStep: PropTypes.func,
  stepInitialValues: PropTypes.shape({
    code: PropTypes.string,
    from: PropTypes.string,
    to: PropTypes.string,
    invoice_total: PropTypes.number,
    bestPrice: PropTypes.number,
    volumes: PropTypes.arrayOf(
      PropTypes.shape({
        quantity: PropTypes.number,
        weight: PropTypes.number,
        height: PropTypes.number,
        width: PropTypes.number,
        length: PropTypes.number,
      })
    ),
  }).isRequired,
};

QuotationSignup.defaultProps = {
  previousStep: () => {},
};

export default QuotationSignup;
