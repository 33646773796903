import gql from 'graphql-tag';

export default gql`
  mutation signup(
    $document: String!
    $name: String!
    $responsible: String!
    $email: String!
    $phone: String!
    $password: String!
    $password_confirmation: String!
    $utm: UtmWebsiteInputs
  ) {
    signup(
      document: $document
      name: $name
      responsible: $responsible
      email: $email
      phone: $phone
      password: $password
      password_confirmation: $password_confirmation
      utm: $utm
    ) {
      token
    }
  }
`;
