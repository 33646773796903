import client from '../apollo/client';
import signupQuery from '../queries/signup';

export const signup = (variables, utm) => {
  return client.mutate({
    variables: { ...variables, utm },
    mutation: signupQuery,
  });
};

export default {
  signup,
};
