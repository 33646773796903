import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import StepWizard from 'react-step-wizard';
import QuotationNav from './QuotationNav';
import QuotationAddress from './QuotationAddress';
import QuotationVolumes from './QuotationVolumes';
import QuotationSignup from './QuotationSignup';

const Quotation = ({
  initialStep,
  isHashEnabled,
  isLazyMount,
  onStepChange,
  stepInitialValues,
}) => {
  const [stepValues, updateStepValues] = useState(stepInitialValues);

  const handleStepChange = stats => {
    if (window && typeof window.scrollTo === 'function') {
      window.scrollTo(0, 0);
    }
    onStepChange({ stats, stepValues });
  };

  return (
    <StepWizard
      nav={<QuotationNav />}
      initialStep={initialStep}
      isHashEnabled={isHashEnabled}
      isLazyMount={isLazyMount}
      onStepChange={handleStepChange}
    >
      <QuotationAddress hashKey="ceps" />
      <QuotationVolumes
        hashKey="carga"
        stepInitialValues={stepValues.volumes}
        updateStepValues={updateStepValues}
      />
      <div hashKey="cadastro">
        {stepValues.result && (
          <QuotationSignup stepInitialValues={stepValues.result || {}} />
        )}
      </div>
    </StepWizard>
  );
};

Quotation.propTypes = {
  initialStep: PropTypes.number,
  isHashEnabled: PropTypes.bool,
  isLazyMount: PropTypes.bool,
  onStepChange: PropTypes.func,
  stepInitialValues: PropTypes.objectOf(
    PropTypes.shape({
      volumes: PropTypes.objectOf(
        PropTypes.shape({
          from: PropTypes.string,
          to: PropTypes.string,
        })
      ),
      result: PropTypes.objectOf(
        PropTypes.shape({
          from: PropTypes.string,
          to: PropTypes.string,
          invoice_total: PropTypes.number,
          volumes: PropTypes.arrayOf(
            PropTypes.shape({
              quantity: PropTypes.number,
              weight: PropTypes.number,
              height: PropTypes.number,
              width: PropTypes.number,
              length: PropTypes.number,
            })
          ),
        })
      ),
    })
  ),
};

Quotation.defaultProps = {
  initialStep: 1,
  isHashEnabled: false,
  isLazyMount: false,
  onStepChange: () => {},
  stepInitialValues: { volumes: {} },
};

export default memo(Quotation);
