import gql from 'graphql-tag';

export const createQuotation = gql`
  mutation createQuotation(
    $from: String!
    $to: String!
    $invoice_total: Float!
    $volumes: [VolumeInput]!
    $utm: UtmWebsiteQuotationInputs
  ) {
    createQuotation(
      from: $from
      to: $to
      invoice_total: $invoice_total
      volumes: $volumes
      utm: $utm
    ) {
      code
      best_price
    }
  }
`;

export default {
  createQuotation,
};
