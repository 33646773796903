import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import { Button, Col, Row, FormGroup, Spinner } from 'reactstrap';
import Yup from '../../utils/yup';
import EmailField from '../EmailField';
import PhoneField from '../PhoneField';
import TextField from '../TextField';
import NumberField from '../NumberField';
import Masks from '../../utils/masks';
import SubmitCol from './styled';
import SectionSubtitle from '../SectionSubtitle';
import PasswordField from '../PasswordField';

const validationSchema = Yup.object().shape({
  document: Yup.string()
    .matches(/\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}/, 'Informe um CNPJ válido')
    .required('Campo obrigatório'),
  responsible: Yup.string().required('Campo obrigatório'),
  company_name: Yup.string().required('Campo obrigatório'),
  email: Yup.string()
    .matches(/\S+@\w+\.\w+/, 'Informe um email válido')
    .required('Campo obrigatório'),
  phone: Yup.string()
    .matches(/\([1-9]{2}\) \d{4,5}-\d{4}/, 'Informe um telefone válido')
    .required('Campo obrigatório'),
  password: Yup.string().required('Campo obrigatório'),
  password_confirmation: Yup.string()
    .oneOf([Yup.ref('password'), null], 'As senhas não são iguais')
    .required('Campo obrigatório'),
  terms: Yup.bool()
    .oneOf(
      [true],
      'Para se cadastrar é necessário aceitar os termos e condições'
    )
    .required('Para se cadastrar é necessário aceitar os termos e condições'),
});

const SignupForm = ({ onSubmit }) => (
  <Formik
    initialValues={{
      document: '',
      responsible: '',
      company_name: '',
      email: '',
      phone: '',
      password: '',
      password_confirmation: '',
      terms: '',
    }}
    validationSchema={validationSchema}
    onSubmit={onSubmit}
    render={({ isSubmitting }) => (
      <Form>
        <FormGroup>
          <SectionSubtitle className="text-primary">
            Identificação da Empresa
          </SectionSubtitle>
          <Row form>
            <Col xs="12" md="6">
              <NumberField name="document" label="CNPJ" mask={Masks.cnpj} />
            </Col>
            <Col xs="12" md="6">
              <TextField name="company_name" label="Nome fantasia" />
            </Col>
          </Row>
        </FormGroup>
        <FormGroup>
          <SectionSubtitle className="text-primary">
            Dados para acesso à plataforma
          </SectionSubtitle>
          <Row form>
            <Col xs="12" md="4">
              <TextField name="responsible" label="Nome" />
            </Col>
            <Col xs="12" md="4">
              <EmailField name="email" label="E-mail" />
            </Col>
            <Col xs="12" md="4">
              <PhoneField name="phone" label="Telefone" />
            </Col>
          </Row>
          <Row form>
            <Col xs="12" md="4">
              <PasswordField name="password" label="Senha de acesso" />
            </Col>
            <Col xs="12" md="4">
              <PasswordField
                name="password_confirmation"
                label="Repita a senha"
              />
            </Col>

            <SubmitCol xs="12" md="4">
              <FormGroup>
                <Button
                  disabled={isSubmitting}
                  type="submit"
                  size="lg"
                  className="d-flex justify-content-center align-items-center"
                  block
                >
                  {isSubmitting ? (
                    <>
                      <Spinner size="sm" color="light" />
                      <small> Carregando</small>
                    </>
                  ) : (
                    'Cadastrar'
                  )}
                </Button>
              </FormGroup>
            </SubmitCol>
          </Row>
          <Row form>
            <Col xs="12">
              <TextField
                type="checkbox"
                name="terms"
                label={
                  <>
                    Concordo com os&nbsp;
                    <a href="/termos" target="_blank">
                      termos e condições
                    </a>
                    &nbsp;da Central do Frete.
                  </>
                }
              />
            </Col>
          </Row>
        </FormGroup>
      </Form>
    )}
  />
);

SignupForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default SignupForm;
