import styled from 'styled-components';
import { Row } from 'reactstrap';

export const VolumeRow = styled(Row)`
  font-size: 0.875rem;

  strong {
    color: #ced4da;
  }
`;

export default {
  VolumeRow,
};
